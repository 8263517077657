import React from 'react'

const Contact = () => {
  return (
    <div name = 'contact' className = 'w-full h-screen bg-[#0a192f] flex justify-center items-center p-4'>
        <form method = 'POST' action="https://getform.io/f/ffb6f7e0-6885-4350-af1a-c5fa47b5bbf4" className = 'flex flex-col max-w-[600px] w-full'>
            <div className = 'pb-8'>
            <p className = 'text-4xl font-bold inline border-b-4 border-red-300 text-gray-300'>Contact</p>
            <p className = 'text-gray-300 py-4'>Email me by submitting the form below!</p>
            </div>
            <input className ='bg-[#ccd6f6] p-2'type="text" placeholder = 'Name' name ='name'></input>
            <input className = 'my-4 p-2 bg-[#ccd6f6]'type="email" placeholder = 'Email' name ='email'></input>
            <textarea className = 'bg-[#ccd6f6] p-2' name="message" rows="10" placeholder = 'Message'></textarea>
            <button className ='text-white border-2 hover:bg-red-300 hover:border-red-300 px-4 py-3 my-8 mx-auto flex items-center'>Let's Collaborate</button>
        </form>
    </div>
  )
}

export default Contact